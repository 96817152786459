.footer-small{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 4px 4px rgba(0,0,0, .1);
    border-radius: 5px;
    color: rgb(100,100,100);

    @media screen and (max-width: 767px) {
        flex-direction: column;

        & > * {
            margin-bottom: 10px;
        }
    }

    .link-logo {
        display: flex;
        align-items: center;
    }

    a {
        color: inherit;
        display: flex;
        align-items: center;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            justify-content: center;
        }

        @media screen and (max-width: 575px) {
            img {
                width: 90%;
                margin-top: 3px;
            }
        }
    }

    span {
        color: inherit;
    }
}

.footer__logo {
    margin-left: 10px;

    @media screen and (max-width: 991px) {
        margin-left: 0;
    }
}