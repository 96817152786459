.bold {
  font-weight: 600;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

.padding-left {
  padding: 0 6.5rem 0 $padding;
  @media screen and (max-width: 1499px){ padding: 0 6.5rem 0 $padding1499; }
  @media screen and (max-width: 991px){ padding: $mobile-padding; }
}

.padding-right {
  padding: 0 $padding 0 6.5rem;
  @media screen and (max-width: 1499px){ padding: 0 $padding1499 0 6.5rem; }
  @media screen and (max-width: 991px){ padding: $mobile-padding }
}

.separate {
  color: rgba(0,0,0, .5);
  @media screen and (max-width: 991px) { display: none }
}