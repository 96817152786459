.room__slider {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width:1199px) {
    align-self: center;
  }

  @media screen and (max-width:991px) {
    width: 75%;
  }

  @media screen and (max-width:575px) {
    width: 100%;
  }

  .room__image {
    width: 50vw;
    height: 35vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (max-width:991px) {
      width: 75vw;
      height: 52vw;
    }

    @media screen and (max-width:575px) {
      width: 100vw;
      height: 70vw;
    }
  }

  &--left {
    .slick-prev {
      right: 110px;
      bottom: 10px;
      padding-left: 2px;
      margin: auto;
      left: unset;

      @media screen and (max-width: 991px) {
        right: 60px;
        bottom: 2px;
      }
    }

    .slick-next {
      right: 40px;
      bottom: 10px;
      padding-left: 2px;
      margin: auto;

      @media screen and (max-width: 991px) {
        right: 10px;
        bottom: 2px;
      }
    }
  }

  &--right {
    .slick-prev {
      left: 40px;
      bottom: 10px;
      padding-left: 2px;
      margin: auto;

      @media screen and (max-width: 991px) {
        right: 60px;
        bottom: 2px;
        padding-left: 2px;
        margin: auto;
        left: unset;
      }
    }

    .slick-next {
      left: 110px;
      bottom: 10px;
      padding-left: 2px;
      margin: auto;
      right: unset;

      @media screen and (max-width: 991px) {
        right: 10px;
        left: unset;
        bottom: 2px;
        padding-left: 2px;
        margin: auto;
      }
    }
  }
}

.apartment__slider, .room__slider {
  .slick-arrow {
    height: 55px;
    width: 55px;
    border-radius: 15%;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, .2);
    background-color: rgba(250, 250, 250, .7);
    font-size: 1.5em;
    color: $color-main;
    transition: background-color .3s;

    @media screen and (max-width: 991px) {
      height: 35px;
      width: 35px;
      font-size: 1em;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: white;
    }
  }
}

.apartment__slider {
  width: 100%;

  .slick-arrow {
    transform: translateY(50%);
  }

  .apartment__img {
    height: 37vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 575px) {
      height: 55vw;
    }
  }

  .slick-prev {
    left: 20px;
    bottom: 50%;
    padding-left: 2px;
    margin: auto;
  }

  .slick-next {
    right: 20px;
    bottom: 50%;
    padding-left: 2px;
    margin: auto;
    left: unset;
  }

  .slick-slide {
    opacity: 1;
  }
}