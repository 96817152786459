.room-widget {
  height: 90vh;
  width: 100%;
  margin-top: 40px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    display: none;
  }

  &__element {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__back {
    justify-self: flex-start;
    align-self: flex-start;
    transition: transform .3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.hide-init {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.widget-legend {
  display: grid;
  position: absolute;
  top: 0;
  right: 34%;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  grid-column-gap: 20px;
  transform: translateX(50%);

  &__container {
    display: flex;
    align-items: center;
  }

  &__color {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;

    &--1 {
      background-color: rgb(218,165,32);
    }

    &--2 {
      background-color: rgb(254, 237, 221);
    }

    &--3 {
      background-color: rgb(100, 149, 237);
    }

    &--4 {
      background-color: rgb(197, 73, 32);
    }

    &--5 {
      background-color: rgb(169, 169, 169);
    }

    &--7 {
      background-color: rgb(167, 86, 163);
    }
  }
}