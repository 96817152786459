.footer-subsites {
  position: relative;
  margin-bottom: 15rem;

  img {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    margin: 0;
  }

  &__content {
    display: flex;
    position: absolute;
    bottom: 0;
    left: $padding;
    right: $padding;
    justify-content: space-between;
    padding: 4rem 8.5rem;
    line-height: 1.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.639093137254902) 0%,
      rgba(255, 255, 255, 0.9164040616246498) 20%,
      rgba(255, 255, 255, 1) 50%
    );
    transform: translateY(50%);
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    @media screen and (max-width: 1499px) {
      left: $padding1499;
      right: $padding1499;
      padding: 2rem 3rem;
    }

    @media screen and (max-width: 1199px) {
      align-items: center;
      flex-direction: column;
    }

    @media screen and (max-width: 991px) {
      left: $mobile-padding;
      right: $mobile-padding;
      flex-direction: column;
      position: static;
      transform: translateY(0);
      margin: 5rem 1rem;
      align-items: flex-start;
    }
  }

  &__logo {
    margin-bottom: 2rem;
  }

  &__buttons-container {
    display: flex;
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 1rem;
  }

  &__icon {
    width: 30px;
  }

  &__text-container {
    display: flex;
    margin-top: 2rem;

    @media screen and (max-width: 991px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__img {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__address {
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }
}
