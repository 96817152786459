*{font-family: 'Montserrat', Arial, Helvetica, sans-serif; font-weight: $light;}

html {
    font-size: 62.5%;

    @media screen and (max-width: 1199px) {
        font-size: 50%;
    }
}

body{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    margin: 0;
    font-size: 1.6rem;

    @media screen and (max-width: 1919px) {
        font-size: 1.3em;
    }

    @media screen and (max-width: 991px) {
        font-size: 1.6em;
    }

    @media screen and (max-width: 767px) {

    }

    @media screen and (max-width: 575px) {
        font-size: 1.3em;
    }
}
p{font-size: 1.6rem;}
div, span, p, textarea, input, select, li, td, strong, em {color:$color-main; font-family: 'Montserrat', sans-serif; line-height: 1.4; font-size: 1.6rem}
a{color:inherit; outline:none; text-decoration:none; font-size: 1em; font-family: inherit}
a:hover{color:#000; text-decoration:none;}
a:active{color:#666;}
a:focus{text-decoration: none; outline:none;}
h1,h2,h3,h4,h5{padding:0; line-height: 1.3; font-family: 'PT Serif', serif; font-weight: 300;}

h1{font-size:2.6em; @media screen and (max-width:991px) { font-size: 2.1em }; }
h2{font-size:2.3em; @media screen and (max-width:991px) { font-size: 1.9em }; }
h3{font-size: 2.1em; color:$color-main; @media screen and (max-width:991px) { font-size: 1.7em };}
h4{font-size:1.6em;}
h5{font-size:1.5em;}
img{border:0; height: auto; max-width: 100%;}
video {max-width: 100%;  height: auto;}
th{text-align:left;}
ul{padding:0; margin:0;}
ul li{
    list-style: none outside none;
    padding-left: 5px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pages {
    ul li {
        &::before {
            display: inline-block;
            content: '';
            -webkit-border-radius: 0.375rem;
            border-radius: 0.375rem;
            height: 0.75rem;
            width: 0.75rem;
            margin-right: 0.5rem;
            background-color: #bdbdbd;
        }
    }
}

.padding-container{
    padding: 0 $padding;
    @media screen and (max-width: 1499px){ padding: 0 $padding1499; }
    @media screen and (max-width: 991px){ padding: 0 $mobile-padding; }
}
.margin-container{
    margin: 0 $padding;
    @media screen and (max-width: 1499px){ margin: 0 $padding1499; }
    @media screen and (max-width: 991px){ margin: 0 $mobile-padding; }
}
footer{
    margin-top: auto;
}
.no-gutters{
    & > .col-left{
        padding-right: 30px;

        @media screen and (max-width: 991px) {
            padding-right: 0;
        }
    }
    & > .col-right{
        padding-left: 30px;

        @media screen and (max-width: 991px) {
            padding-left: 0;
        }
    }
}

table {
    width: 100%;
    margin: 30px 0;
    border-collapse: collapse;
    overflow-x: scroll;
    display: block;
    text-align: left;

    tr,th,td {
        border: 1px solid $color-main;
        padding: 0.9375rem;
    }


}
