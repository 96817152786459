.subsites-header {
  height: 26vw;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;


  &__title-container {
    background-color: $color-main;
    color: white;

    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 991px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__title {
    font-size: 2.8em;
    color: inherit;
    font-family: "PT Serif", Serif;
  }
}

.buttons__container {
  display: flex;

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }
}

.bread-crumb {
  display: flex;
  justify-items: flex-start;
  font-family: 'Roboto', sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(241, 241, 241);
  color: rgb(129, 130, 131);;

  &__item {
    display: flex;
    justify-items: flex-start;
    font-size: 1.4rem;
    color: rgb(60, 60, 60);
    font-weight: 600;

    &:not(:last-child) {
      margin-right: 3px;

      &::after {
        content: "/";
        color: grey;
        align-self: center;
        margin-left: 5px;
        font-weight: 300;

        @media screen and (max-width: 1499px) {
          margin-top: 0;
        }

        @media screen and (min-width: 1921px) {
          margin-top: -5px;
        }
      }
    }

    a {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      color: rgb(129, 130, 131);
      font-weight: 300;
      transition: black .3s;

      @media screen and (max-width: 991px) {
        font-size: 1.4rem;
      }

      &:hover {
        color: black;
      }

      img {
        max-width: 43px;
        max-height: 24px;
        margin-right: 5px;
        margin-top: -4px;

        @media screen and (min-width: 1921px) {
          margin-top: -1px;
        }
      }
    }

    &.active {
      a {
        color: $color-main;
      }
    }
  }
}