.room {
  display: flex;

  @media screen and (max-width:991px) {
    flex-direction: column;
    &:not(:first-of-type) {
      margin-top: 15rem;
    }
  }

  @media screen and (max-width:575px) {
    &:not(:first-of-type) {
      margin-top: 5rem;
    }
  }

  &:nth-child(4n + 4) {
    flex-direction: row-reverse;

    @media screen and (max-width:991px) {
      flex-direction: column;
    }
  }

  &__info {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;

    @media screen and (max-width:991px) {
      width: 100%;
      padding-bottom: 3rem;
    }
  }

  &__name {
    font-family: "PT Serif", Serif;
    font-size: 1.575em;

    @media screen and (max-width: 575px) {
      font-size: 1.475em;
    }
  }

  &__basic {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125em;
    margin-bottom: 4rem;

    @media screen and (max-width: 991px) {
      justify-content: space-evenly;
      line-height: 2;
      text-align: center;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__beds, &__rooms, &__guests {
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
  }

  &__amenities {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 300;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.5rem;
    margin-bottom: 4.6875em;

    @media screen and (max-width: 991px) {
      margin-bottom: 1em;
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 3em;
    }
  }

  &__book {
    display: flex;
    align-self: flex-end;
    margin-top: auto;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 991px) {
      justify-content: space-evenly;
      margin-top: 3rem;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__price {
    font-size: 1.3125em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;

    @media screen and (max-width: 575px) {
      margin-bottom: 1em;
    }
  }

  &__type {
    display: flex;
    padding: 4rem 0;

    @media screen and (max-width: 991px) {
      justify-content: center;
      padding: 4rem 2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    width: 6.5rem;
  }

  &__separator {
    height: 3px;
    background-image: linear-gradient(left , rgba(43,61,113, 0) 15%, rgb(43,61,113) 50%, rgba(43,61,113, 0) 85%);

    @media screen and (max-width: 991px) {
      background-image: linear-gradient(left , rgba(43,61,113, 0) 5%, rgb(43,61,113) 50%, rgba(43,61,113, 0) 95%);
    }
  }

  .subsites__button {
    font-size: 1em;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
  }
}

.amenity {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  align-items: center;

  &__name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
  }
}

.apartment {
  &__slider {
    z-index: 0;
  }
  .room__type {
    padding-bottom: 2rem;
    padding-top: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: -10rem;
    border-radius: 10px;
    border: 1px solid white;
    z-index: 1;
    position: relative;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, .2);

    @media screen and (max-width:991px) {
      grid-template-columns: 100%;
      margin-top: -3rem;
      overflow: hidden;
      border-radius:  0 0 10px 10px;
    }
  }

  &__info {
    padding: 3.2rem 5.5rem;
    background-image: linear-gradient(to top, white 80%, rgba(white, .8));
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    @media screen and (max-width: 1199px) {
      padding: 3.2rem 1.5rem;
    }
  }

  &__text-container {
    padding: 3.2rem 5.5rem;
    color: white;
    background-image: linear-gradient(to top, $color-main 80%, rgba($color-main, .8));
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    @media screen and (max-width: 1199px) {
      padding: 3.2rem 1.5rem;
    }

    @media screen and (max-width: 991px) {
      padding: 3.2rem 8.5rem;
    }

    @media screen and (max-width: 575px) {
      padding: 3.2rem 1rem;
    }
  }

  &__description, .subsites__button, &__price {
    color: inherit;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    font-size: 1.125em;

    @media screen and (max-width: 991px) {
      font-size: 1em;
    }
  }

  &__description {
    margin-bottom: 30px;
    font-weight: 300;
  }

  &__price {
    align-self: center;
    margin-top: 15px;
    font-weight: 300;
  }

  .subsites__button {
    font-size: 1.2em;
    padding: .5em 1em;
    display: block;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;

    @media screen and (max-width: 991px) {
      width: 80%;
      font-size: 1em;
    }

    &:hover {
      color: $color-main;
    }
  }
}