// =========================
// Modules and Variables
// =========================

$color-main: #4a2e47;
$color-extra: #91b432;
$color-font-light: #fff;
$color-font-dark: #525252;
$color-menu: red;
// Font weights
$light: 300;
$regular: 400;
$bold: 700;
$black: 900;

// Base Font
$font-family-base: 'Montserrat';
$font-weight-base: $light;
$font-size-base: 62.5%;
$font-line-height-base: 1.4;

// Fixed Font
$font-family-extra: 'Alegreya';

// Paddings/Margins
$padding: 16vw;
$padding1499: 8vw;
$mobile-padding: 3vw;

// Paragraph Styles
$paragraph-color      : #505050;
$paragraph-size       : rem(17px);
$paragraph-line-height: 1.5em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : #3e3e3e;
$header-size       : rem(40px);
$header-line-height: 1.25em;
$header-margin     : $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color      : #505050;
$blockquote-size       : rem(23px);
$blockquote-line-height: 1.5em;
$blockquote-margin     : $paragraph-margin * 1.5;

// Links
$link-color: $color-main;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 700;
$border-color: #DADADA;
$base-font-size: 16px;