.header {
  position: relative;
}

.navigation {
  width: 100%;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;

  @media screen and (max-width: 991px) {
    justify-content: space-between;
  }


  &__logo-container {
    justify-self: flex-start;
    width: 9vw;

    @media screen and (max-width: 991px) {
      width: 20vw;
    }

    @media screen and (max-width: 575px) {
      width: 28vw;
    }
  }

  &__logo {

  }

  &__links {
    display: flex;
    z-index: 2;

    @media screen and (max-width: 991px) {
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      background-color: white;
      justify-items: center;
      align-items: center;
      padding: 2em 0;
      transform: scaleX(0);
      transform-origin: right;
      border-top: 1px solid $color-main;
      border-bottom: 1px solid $color-main;
      line-height: 1.5;
      transition: all .3s;
      padding-left: 20px;
    }

    &--visible {
      transform: scaleX(1);
    }
  }

  &__link {
    font-size: 1.1em;
    font-family: 'Montserrat', sans-serif;
    color: $color-main;
    border-bottom: 1px solid rgba(0,0,0, 0);
    transition: all .3s;
    text-transform: initial;

    @media screen and (max-width: 991px) {
      font-size: 1.5em;
      margin-bottom: 2em;
    }

    @media screen and (max-width: 575px) {
      font-size: 1.2em;
    }

    &:hover {
      border-bottom: 1px solid rgba(0,0,0, 1);
      cursor: pointer;
    }
  }

  &__link-wraper {
    &:not(:last-child) {
      margin-right: 4vw;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 1em;
    }
  }
}

.submenu.hide-menu {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.submenu {
  position: absolute;
  top: 100%;
  right: -1em;
  background-color: rgba($color-main, .95);
  transition: all .3s;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .menu__item {
    text-align: right;
    margin-right: 0;
    padding: 5px;
    width: max-content;

    a {
      color: white;

      &:hover {
        border-bottom: 1px solid white;
      }
    }
  }

  @media screen and (max-width: 991px) {
    position: static;
    padding-left: 15px;
    align-items: flex-start;
  }
}

.has-submenu {
  position: relative;
}