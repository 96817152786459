.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  :focus{outline: none;}

  .input-effect {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 30px;
  }

  .input {
    margin-top: 5px;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #505052;
    background-color: transparent;
    position: relative;
    width: 100%;

    & ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #3399FF;
      transition: 0.4s;
    }

    &:focus ~ .focus-border {
      width: 100%;
      transition: 0.4s;
    }

    & ~ label {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0px;
      color: #505052;
      transition: 0.3s;
      z-index: -1;
      letter-spacing: 0.5px;
    }

    &:focus ~ label {
      top: -16px;
      font-size: 12px;
      color: #3399FF;
      transition: 0.3s;
    }
  }

  .has-content.input ~ .focus-border {
    width: 100%;
    transition: 0.4s;
  }

  .has-content.input ~ label {
    top: -16px;
    font-size: 12px;
    color: #3399FF;
    transition: 0.3s;
  }

  textarea {
    margin-bottom: -7px;
  }

  .form-button {
    margin-top: 30px;
    transition: color .25s, background-color .25s;
    border: 1px solid #505052;
    display: inline-block;
    color: #505052;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    width: 100px;

    &:hover {
      background-color: rgb(71, 30, 21);
      color: white;
      cursor: pointer;
    }
  }
}

