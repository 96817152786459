.button {
  background-color: $color-main;
  color: white;
  padding: .8em 1.5em;
  font-family: 'PT Serif', serif;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: .5px;
  transition: background-color .3s;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #91b432;
  }
}

.subsites__button {
  font-size: 1.2em;
  border-radius: 5px;
  padding: 1em 2em;

  @media screen and (max-width: 991px) {
    padding: .5em 1em;
  }

  @media screen and (max-width: 767px) {
    font-size: 1em;
    padding: .5em 1em;
  }

  @media screen and (max-width: 575px) {
    text-align: center;
  }

  &:first-child {
    margin-right: 3rem;

    @media screen and (max-width: 991px) {
      margin-right: 1rem;
    }
  }

  a {
    color: inherit;
  }

  &--footer {
    font-size: 1em;
    border-radius: 5px;
    padding: 1rem 2rem;
  }
}

.subsites__button--white {
  border: 1px solid white;
  color : white;
  background-color: $color-main;
  transition: all .3s;

  &:hover {
    background-color: white;
    color: $color-main;
    border-color: $color-main;
  }
}

.subsites__button--main {
  border: 1px solid $color-main;
  color : $color-main;
  background-color: white;
  transition: all .3s;

  &:hover {
    background-color: $color-main;
    color: white;
    border-color: white;
  }
}