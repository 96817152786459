// =========================
// Fonts
// =========================

@font-face {
   font-family: 'Montserrat';
   font-weight: 300;
   src: url('../../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'PT Serif';
  font-weight: 400;
  src: url('../../fonts/PTSerif-Regular.ttf');
}
