.posts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.post {
  display: flex;

  &__content {

  }

  &__basic-info {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    letter-spacing: 0.8px;
  }
}

.hotres__button {
  width: 100%;
  margin: 0 auto;
  background-color: $color-main;
  padding: 1rem;
  color: white;
  font-size: 1.375em;
  display: block;
  margin-top: 2rem;
  border-radius: 5px;
  transition: all .3s;
  font-family: 'PT Serif';
  text-transform: lowercase;
  border: 1px solid transparent;

  &:hover {
    color: $color-main;
    background-color: white;
    cursor: pointer;
    border: 1px solid $color-main;
  }
}

.hotresChooser {
  position: absolute;
  background-color: rgba(255, 255, 255, .95);
  padding: 3rem;
  border-radius: 7px;
  right: $padding;
  bottom: 8.5rem;

  @media screen and (max-width: 1499px) {
    right: $padding1499;
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }

  &__title {
    font-size: 2em;
    margin-bottom: 15px;
    margin-top: 0;
  }

  &__input {
    justify-content: space-between;
    display: grid;
    margin-bottom: 2rem;
    align-items: center;

    div {
      font-size: 1.125em;
    }

    input {
      background-color: inherit;
      display: inline-block;
      border: 1px solid black;
      padding: .5rem;
      font-size: 1.125em;
      border-radius: 3px;
    }
  }
}



.ui-datepicker-month, .ui-datepicker-year{
  text-transform:uppercase;
  font-weight:normal;

}

.ui-datepicker
{
  background:#fff;
  color:#000;
  padding: 10px;
  font-family:Arial;

}

.ui-datepicker  *
{
  font-size:15px;
}

.ui-state-disabled{
  color:#ccc !important;
  font-weight:normal !important;
}
.ui-datepicker-prev {
  float:left;

}
.ui-datepicker-prev::before{
  color: #000;
  font-size:20px;
  content:'\2039';
  background:#eee;
  border-radius:100%;
  height:30px;
  width:30px;
  line-height:30px;
  cursor:pointer;
  font-weight:bold;
  display:block;
}


.ui-datepicker-next::before{
  color: #000;
  font-size:20px;
  content:'\203A';
  background:#eee;
  border-radius:100%;
  height:30px;
  width:30px;
  line-height:30px;
  cursor:pointer;
  font-weight:bold;
  display:block;
}


.ui-datepicker .ui-datepicker-next
{

  float:right;
}
.ui-icon
{
  display:none;
}
.ui-datepicker .ui-datepicker-title
{
  font-size:15px;
  font-weight:bold;
  height:30px;
  line-height:30px;
}

.dp-highlight {
  background: #404040;
  color: #000;
}



.ui-datepicker a,
.ui-datepicker a:hover {
  text-decoration: none;

}

.ui-datepicker a:hover,
.ui-datepicker td:hover a {
  color: #e57c13;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.ui-datepicker .ui-datepicker-header {
  margin-bottom: 4px;
  text-align: center;
}









.ui-datepicker .ui-datepicker-prev:hover,.ui-datepicker .ui-datepicker-next:hover
{
  color:#3d95cc;
}

.ui-datepicker .ui-icon {

}
.ui-datepicker .ui-datepicker-calendar {
  table-layout: fixed;
  max-width:400px;
}

.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
  padding: 10px 10px;
  color:#000;
  border-left:1px solid #eee;



}


.ui-datepicker .ui-datepicker-calendar th
{color:#404040;
  opacity:.7;
  font-weight:bold;
}


.ui-datepicker-current-day a {color:#fff !important;}

.ui-datepicker .ui-datepicker-calendar td:hover {
  background:#ff8000;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-calendar td a {
  text-decoration: none;
  color:#303030;
  font-weight:bold;
}

.ui-datepicker .ui-datepicker-calendar td:hover a {
  color:#fff;

}

.ui-widget-content{
  display:none;
  margin-left: 0px;
  box-shadow:1px 1px 8px 1px rgba(0,0,0,0.2);
  z-index: 9999999 !important;

}
.ui-datepicker .ui-datepicker-current-day {
  background:#ff5000;
}
.ui-datepicker .ui-datepicker-current-day a {
  color:#fff;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
  background-color: rgba(255,255,255,0.1);
  color:#e57c13;
  cursor: default;
}

.between-date {background:red !important}