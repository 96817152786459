.section-1 {
  margin-bottom: 8rem;
  height: 100vh;
  background-image: url("../../images/top.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media screen and (max-width: 991px) {
    height: 60vw;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
    height: 90vw;
  }

  &__content {
    display: flex;
    padding-bottom: 8.5rem;
    align-items: flex-end;
    height: 89vh;
    width: 100%;

    @media screen and (max-width: 991px) {
      height: 90%;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 1em;
      height: 85%;
    }

    @media screen and (max-width: 575px) {
      height: 85%;
    }
  }

  &__title {
    color: white;
    width: 55%;
    text-shadow: 0 0 20px black;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    h1 {
      font-family: 'PT Serif', serif;
      font-weight: 300;
    }
  }

  &__form {

  }
}

.section-2 {


  &__header {
    font-weight: 400;
    font-family: 'PT Serif', serif;
    color: $color-main
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: $color-main;
    margin-bottom:  8rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__description {
    width: 45%;
    line-height: 1.5;
    font-size: 1.1em;
    color: $color-main;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 2em;
    }
  }

  &__slogan {
    width: 45%;
    font-size: 1.7em;
    line-height: 1.3;
    font-weight: 300;
    color: $color-main;
    font-family: 'Montserrat', sans-serif;

    @media screen and (max-width: 991px) {
      font-size: 1.3em;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.section-3 {
  height: 55vw;
  width: 100%;
  background-image: url('../../images/palac-bg.jpg');
  position: relative;
  margin-bottom: 27rem;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    height: 90vw;
    margin-bottom: 45vw;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    background-image: unset;
    padding: 0 $mobile-padding;
    margin-bottom: 10rem;
  }



  &__content {
    position: absolute;
    bottom: -7%;
    width: calc(100% - (2 * #{$padding}));
    background-color: white;
    display: flex;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);


    @media screen and (max-width: 1499px) {
      width: calc(100% - (2 * #{$padding1499}));
    }

    @media screen and (max-width: 991px) {
      width: calc(100% - (2 * #{$mobile-padding}));
      flex-direction: column;
      bottom: -20%;
    }

    @media screen and (max-width: 767px) {
      position: relative;
      width: 100%;
      margin-top: 10rem;
    }


  }

  &__img {
    width: 40%;
    height: auto;
    line-height: 0;
    background-image: url("../../images/palac-foto2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 40vw;
    }
  }

  &__description {
    padding: 3em 5em 5.5em 5em;
    width: 60%;
    background-image: url('../../images/palac-drawing.png');
    background-repeat: no-repeat;
    background-position: 110% 20%;

    @media screen and (max-width: 1199px) {
      padding: 3em 2em 5em 2em;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      padding: 3em 2em 5em 2em;
    }
  }

  &__header {
    color: $color-main;
    font-family: 'PT Serif', serif;
    font-weight: 300;
    margin: 0 0 4rem 0;

    @media screen and (max-width: 1199px) {
      margin: 0 0 2rem 0;
    }

    @media screen and (max-width: 767px) {
      margin: 0 0 1em 0;
    }
  }

  &__text {
    color: $color-main;
    font-size: 1em;
    line-height: 1.8;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
  }

  &__button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.offer {
  width: 100%;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin-top: 10rem;

  @media screen and (max-width: 991px) {
    background-image: none !important;
  }

  &--right {
    background-position: right center;
  }

  &--left {
    background-position: left center;
  }

  &--section-4 {
    background-image: url('../../images/restauracja-bg.png');
    background-position: left 22% center;
  }

  &--section-5 {
    background-image: url('../../images/event-bg.png');
    background-position: right 20% center;
  }

  &--section-7 {
    background-image: url('../../images/house-bg.jpg');
    background-position: left 15% center;
  }

  &--section-8 {
    background-image: url('../../images/water-park-bg.png');
    background-position: right 20% center;
  }

  &__content {
    display: grid;
    grid-column-gap: 5%;
    width: 100%;

    &--left {
      grid-template-columns: minmax(auto, 27vw) 29vw;
      justify-content: flex-end;

      @media screen and (max-width: 1499px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: 100%;
      }

      .offer__img {

        @media screen and (max-width: 991px) {
          grid-row: 1;
        }
      }
    }

    &--right {
      grid-template-columns: 29vw minmax(auto, 27vw);
      justify-content: flex-start;

      @media screen and (max-width: 1499px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: 100%;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 5rem;
      }

      .offer__description {
        grid-column: 2/3;
        grid-row: 1;

        @media screen and (max-width: 991px) {
          grid-column: unset;
          grid-row: unset;
        }
      }

      .offer__img {
        grid-column: 1/2;
        grid-row: 1;

        @media screen and (max-width: 991px) {
          grid-column: unset;
          grid-row: 1;
        }
      }
    }

  }

  &__description {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 15% 0;

    @media screen and(max-width: 991px) {
      padding: 3em 2em 5em 2em;
      box-shadow: 0 0 4px 4px rgba(0,0,0, .05);
    }

  }

  &__header {
    margin-bottom: 2em;
    letter-spacing: 0.9px;
    margin-top: 0;

    @media screen and(max-width: 991px) {
      margin-bottom: 0.5em;
    }
  }

  &__text {
    line-height: 2;
    text-align: justify;
    margin-bottom: 2em;

    @media screen and (max-width: 991px) {
      margin-bottom: 2em;
      line-height: 1.8;
    }
  }

  &__list {
    padding-left: 15%;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 32vw;


    @media screen and (max-width: 1499px) {
      height: auto;
    }
    @media screen and (max-width: 991px) {
      background-position: center center;
      height: 60vw;
    }

    @media screen and (max-width: 575px) {
      background-position: center center;
    }

    &--section-4 {
      background-image: url("../../images/restaurant-foto.jpg");
    }

    &--section-5 {
      background-image: url("../../images/wedding-foto.jpg");
    }

    &--section-7 {
      background-image: url("../../images/house.jpg");
    }

    &--section-8 {
      background-image: url("../../images/water-park.jpg");
    }
  }

  &__list-item {
    font-size: 1.6em;
    letter-spacing: .5px;
    border-bottom: 2px solid rgba($color-main, .0);
    width: fit-content;
    transition: border-bottom-color .3s;
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      font-size: 1.1em;
      margin-bottom: 20px;
    }

    &:hover {
      cursor: pointer;
      border-bottom: 2px solid rgba($color-main, 1);
    }
  }

  &__button {
    align-self: flex-end;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-items: center;

    @media screen and (max-width: 991px) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__sub-header {
    margin-top: -40px;
    margin-bottom: 40px;
    font-size: 1.7em;
    font-family: 'Montserrat';

    @media screen and (max-width: 991px) {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 1.5em;
    }
  }
}

.section-6 {
  height: auto;
  width: 100%;
  position: relative;
  margin-top: 5rem;

  img {
    position: relative;
    z-index: -2;
    width: 100%;
  }

  &__title {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 575px) {
      top: -40px;
    }
  }

  &__circle {

    position: absolute;

    &::before {
      content: '';
      background-color: white;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      opacity: .5;
      z-index: -1;

      @media screen and (max-width: 991px) {
        height: 60px;
        width: 60px;
      }

      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
      }
    }

    &--sport {
      top: 52%;
      left: 27%;

      @media screen and (max-width: 991px) {
        span {
          background-color: #f1b01f;

          &:hover {
            background-color: #f1b01f;
          }
        }

        .section-6__legend-number {
          background-color: #f1b01f !important;
        }
      }
    }

    &--palace{
      top: 77%;
      left: 27%;

      @media screen and (max-width: 991px) {
        span {
          background-color: green;

          &:hover {
            background-color: green;
          }
        }
      }
    }

    &--water{
      top: 39%;
      left: 47%;

      @media screen and (max-width: 991px) {
        span {
          background-color: purple;

          &:hover {
            background-color: purple;
          }
        }
      }
    }

    &--houses{
      top: 47%;
      left: 62%;

      @media screen and (max-width: 991px) {
        span {
          background-color: cornflowerblue;

          &:hover {
            background-color: cornflowerblue;
          }
        }
      }
    }
  }

  &__popup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    color: white;
    transition: background-color .3s;

    @media screen and (max-width: 991px) {
      height: 40px;
      width: 40px;
    }

    @media screen and (max-width: 575px) {
      height: 25px;
      width: 25px;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-main;

      @media screen and (max-width: 991px) {
        cursor: unset;
        background-color: unset;
      }
    }
  }

  &__content {
    position: absolute;
    transform: translate(-100%, -100%);
    width: 400px;
    background-color: white;
    z-index: -1;
    border-radius: 10px;
    padding: 2em 3em;
    display: none;
    left: 50%;
    top: 50%;

    @media screen and (max-width: 991px) {
      position: static;
      display: grid;
      width: 100%;
      padding: 2em 3em;
      border-radius: unset;
      transform: translate(0, 0);
      grid-template-columns: 10% 85%;
      grid-column-gap: 5%;
    }

    @media screen and (max-width: 575px) {
      padding: 1em 1em;
    }

    &--sport {
      transform: translate(0, -100%);

      @media screen and (max-width: 991px) {
        transform: translate(0);
        .section-6__legend-number {
          background-color: #f1b01f;
        }
      }
    }

    &--palace{
      transform: translate(0, -100%);

      @media screen and (max-width: 991px) {
        transform: translate(0);
        .section-6__legend-number {
          background-color: green;
        }
      }
    }

    &--water{
      @media screen and (max-width: 991px) {
        .section-6__legend-number {
          background-color: purple;
        }
      }
    }

    &--houses{
      @media screen and (max-width: 991px) {
        .section-6__legend-number {
          background-color: cornflowerblue;
        }
      }
    }
  }

  &__attraction-name {
    font-size: 1.1em;
    margin-bottom: 0.5em;
    font-weight: 600;

    @media screen and (max-width: 991px) {
      margin-top: 0;
    }

    &:nth-child(3) {
      margin-top: 1em;
    }

  }

  &__legend {
    display: grid;
    grid-template-columns: 100%;
  }

  &__legend-number {
    grid-row: 1/5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: white;
    max-height: 11vw;

  }
}

.active-popup {
  background-color: $color-main;
}

.section-8 {
  margin-top: 16rem;
  width: 100%;
  background-image: url("../../images/map-bg.jpg");
  height: 60vw;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 991px) {
    margin-bottom: 10vw;
    height: auto;
    margin-top: 10vw;
    background-image: unset;
  }

  @media screen and (max-width: 575px) {
    background-image: unset;
  }

  &__header {
    text-align: center;
  }

  &__map-img {
    justify-self: center;
    margin: auto 0;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 40fr 60fr;
    left: $padding;
    right: $padding;
    background-color: white;
    margin-top: -5vw;
    border-radius: 5px;
    box-shadow: 2px 0 10px 2px rgba(0, 0, 0, .2);
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 1499px) {
      left: $padding1499;
      right: $padding1499;
    }

    @media screen and (max-width: 991px) {
      grid-template-rows: auto 60vw;
      height: auto;
      transform: translateY(0);
      grid-template-columns: 100%;
      left: $mobile-padding;
      right: $mobile-padding;
      position: static;
    }
  }

  &__contact {
    padding: 2.5em;
    display: grid;
    grid-template-columns: min-content auto;
    grid-column-gap: 1rem;
  }

  &__text {
    font-family: 'Montserrat', sans-serif;
    line-height:2;
    font-size: .875em;
    margin-bottom: 2em;
  }

  &__logo {
    grid-column: 1/3;
    margin-bottom: 2em;
  }

  &__buttons-container {
    grid-column: 1/3;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    padding: 1em 2em;
    border: 1px solid $color-main;
    border-radius: 5px;
    font-size: .875em;
    font-family: 'Montserrat', sans-serif;
    color: $color-main;
    transition: background-color .3s;

    &:first-child {
      margin-right: 10px;
    }

    @media screen and (max-width: 575px) {
      padding: .5em 1em;
    }

    &:hover {
      background-color: $color-main;
      color: white;
      cursor: pointer;
    }
  }

  &__icon {
    width: 30px;
  }
}

.ue {
  padding: 0 20rem;
  line-height: 1.5;
  font-size: .8em;
  margin-top: 15rem;

  @media screen and (max-width: 1199px) {
    padding: 0 10rem;
  }

  @media screen and (max-width: 991px) {
    padding: 0 0;
  }

  &__logs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    align-items: center;
  }

  &__text-container {
    text-align: center;
    margin-bottom: 6rem;
  }

  &__title {
    margin-bottom: 2rem;
    color: rgb(133, 133, 133);
  }

  &__text {
    margin-bottom: 2rem;
    color: rgb(155, 155, 155);
  }

  &__description {
    color: rgb(155, 155, 155);
  }
}