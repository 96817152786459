.slider__image {
  width:45vw;
  height:25vw;
  background-size:cover;
  background-repeat:no-repeat;
  margin:0 1vw;

  @media screen and (max-width: 991px) {
    height: 60vw;
    width: 80vw;
  }
}

.slick-arrow {
  position: absolute;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  bottom: 10%;
  transform: translateY(-50%);
  z-index: 2;
  border: none;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .2), 0 0 0 10px rgba(255, 255, 255, .5);
  background-color: white;
  font-size: 1.5em;
  color: $color-main;
  transition: box-shadow .3s;

  @media screen and (max-width: 991px) {
    bottom: 2%;

    width: 2em;
    height: 2em;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .2), 0 0 0 12px rgba(255, 255, 255, .8);
  }
}


.slick-prev {
  left: 25%;
  padding-left: 2px;

  @media screen and (max-width: 991px) {
    left: 5vw;
  }
}

.slick-next {
  right: 25%;
  padding-right: 2px;

  @media screen and (max-width: 991px) {
    right: 5vw;
  }
}

.slick-slide {
  opacity: .2;
  transition: opacity .3s;

  @media screen and (max-width: 1200px) {
    opacity: 1;
  }
}

.slick-center {
  opacity: 1;
  transition: opacity .3s;
}